.comments {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 30px;
}

.comment-header {
  display: flex;
  align-items: center;
}

.comment-header .name {
  color: #1ebcf6;
  margin-left: 8px;
  text-decoration: none;
  transition: color 0.2s ease-out;
}

.comment-header .name:hover {
  color: #75daff;
}

.comment-header .from {
  color: gray;
}

.comment-header > * {
  margin-right: 10px;
}

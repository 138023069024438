.social-links {
  display: grid;
  grid-gap: 10px;

  grid-auto-flow: column;
  width: min-content;
  margin-left: auto;
  margin-top: 20px;
}

.last-links-container {
  display: flex;
  align-items: center;
}

.flag-container{
  height: 150px;
  width: 30%;
  margin-right: auto;
  margin-top: 20px;
}

.flag-container img {
  height: 100%;
  box-sizing: border-box;
}

.social-links img {
  height: 52px;
  box-sizing: border-box;
  border: 1px solid #ffffffa3;
  border-radius: 6px;
}

.footer-links {
  display: flex;
  flex-direction: row;
}

@media (max-width: 600px) {
  .footer-links {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .social-links {
    width: unset;
  }
}

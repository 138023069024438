.social-links {
  display: grid;
  grid-gap: 10px;

  grid-auto-flow: column;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  margin-left: auto;
  margin-top: 20px;
}

.last-links-container {
  display: flex;
  align-items: center;
}

.flag-container{
  height: 150px;
  width: 30%;
  margin-right: auto;
  margin-top: 20px;
}

.flag-container img {
  height: 100%;
  box-sizing: border-box;
}

.social-links img {
  height: 52px;
  box-sizing: border-box;
  border: 1px solid #ffffffa3;
  border-radius: 6px;
}

.footer-links {
  display: flex;
  flex-direction: row;
}

@media (max-width: 600px) {
  .footer-links {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .social-links {
    width: unset;
  }
}

.comments {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 30px;
}

.comment-header {
  display: flex;
  align-items: center;
}

.comment-header .name {
  color: #1ebcf6;
  margin-left: 8px;
  text-decoration: none;
  -webkit-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out;
}

.comment-header .name:hover {
  color: #75daff;
}

.comment-header .from {
  color: gray;
}

.comment-header > * {
  margin-right: 10px;
}

.pulsate-fwd {
	-webkit-animation: pulsate-fwd 10s ease-in-out infinite both;
	        animation: pulsate-fwd 10s ease-in-out infinite both;
}

.shake-horizontal {
	-webkit-animation: shake-horizontal 10s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
	        animation: shake-horizontal 10s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
}

.bounce-left {
	-webkit-animation: bounce-left 10s linear infinite both;
	        animation: bounce-left 10s linear infinite both;
}

.bounce-right {
	-webkit-animation: bounce-right 10s both;
	        animation: bounce-right 10s both;
}

.shake-left {
	-webkit-animation: shake-left 10s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
	        animation: shake-left 10s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
}

.shake-right {
	-webkit-animation: shake-right 10s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
	        animation: shake-right 10s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
}

.pulsate-bck {
	-webkit-animation: pulsate-bck 10s ease-in-out infinite both;
	        animation: pulsate-bck 10s ease-in-out infinite both;
}

@-webkit-keyframes pulsate-fwd {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes pulsate-fwd {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  

  /* ----------------------------------------------
 * Generated by Animista on 2021-12-28 10:8:1
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes shake-horizontal {
    0%,
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
      -webkit-transform: translateX(-10px);
              transform: translateX(-10px);
    }
    20%,
    40%,
    60% {
      -webkit-transform: translateX(10px);
              transform: translateX(10px);
    }
    80% {
      -webkit-transform: translateX(8px);
              transform: translateX(8px);
    }
    90% {
      -webkit-transform: translateX(-8px);
              transform: translateX(-8px);
    }
  }
  @keyframes shake-horizontal {
    0%,
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
      -webkit-transform: translateX(-10px);
              transform: translateX(-10px);
    }
    20%,
    40%,
    60% {
      -webkit-transform: translateX(10px);
              transform: translateX(10px);
    }
    80% {
      -webkit-transform: translateX(8px);
              transform: translateX(8px);
    }
    90% {
      -webkit-transform: translateX(-8px);
              transform: translateX(-8px);
    }
  }
  

  /* ----------------------------------------------
 * Generated by Animista on 2021-12-28 10:11:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-left
 * ----------------------------------------
 */
@-webkit-keyframes bounce-left {
    0% {
      -webkit-transform: translateX(-48px);
              transform: translateX(-48px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 1;
    }
    24% {
      opacity: 1;
    }
    40% {
      -webkit-transform: translateX(-26px);
              transform: translateX(-26px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    65% {
      -webkit-transform: translateX(-13px);
              transform: translateX(-13px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    82% {
      -webkit-transform: translateX(-6.5px);
              transform: translateX(-6.5px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    93% {
      -webkit-transform: translateX(-4px);
              transform: translateX(-4px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87%,
    98% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
  }
  @keyframes bounce-left {
    0% {
      -webkit-transform: translateX(-48px);
              transform: translateX(-48px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 1;
    }
    24% {
      opacity: 1;
    }
    40% {
      -webkit-transform: translateX(-26px);
              transform: translateX(-26px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    65% {
      -webkit-transform: translateX(-13px);
              transform: translateX(-13px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    82% {
      -webkit-transform: translateX(-6.5px);
              transform: translateX(-6.5px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    93% {
      -webkit-transform: translateX(-4px);
              transform: translateX(-4px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87%,
    98% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
  }
  

  /* ----------------------------------------------
 * Generated by Animista on 2021-12-28 10:13:3
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-right
 * ----------------------------------------
 */
@-webkit-keyframes bounce-right {
    0% {
      -webkit-transform: translateX(48px);
              transform: translateX(48px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 1;
    }
    24% {
      opacity: 1;
    }
    40% {
      -webkit-transform: translateX(26px);
              transform: translateX(26px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    65% {
      -webkit-transform: translateX(13px);
              transform: translateX(13px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    82% {
      -webkit-transform: translateX(6.5px);
              transform: translateX(6.5px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    93% {
      -webkit-transform: translateX(4px);
              transform: translateX(4px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87%,
    98% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
  }
  @keyframes bounce-right {
    0% {
      -webkit-transform: translateX(48px);
              transform: translateX(48px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 1;
    }
    24% {
      opacity: 1;
    }
    40% {
      -webkit-transform: translateX(26px);
              transform: translateX(26px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    65% {
      -webkit-transform: translateX(13px);
              transform: translateX(13px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    82% {
      -webkit-transform: translateX(6.5px);
              transform: translateX(6.5px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    93% {
      -webkit-transform: translateX(4px);
              transform: translateX(4px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87%,
    98% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
  }


  /* ----------------------------------------------
 * Generated by Animista on 2021-12-28 10:15:46
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-left
 * ----------------------------------------
 */
@-webkit-keyframes shake-left {
    0%,
    100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      -webkit-transform-origin: 0 50%;
              transform-origin: 0 50%;
    }
    10% {
      -webkit-transform: rotate(2deg);
              transform: rotate(2deg);
    }
    20%,
    40%,
    60% {
      -webkit-transform: rotate(-4deg);
              transform: rotate(-4deg);
    }
    30%,
    50%,
    70% {
      -webkit-transform: rotate(4deg);
              transform: rotate(4deg);
    }
    80% {
      -webkit-transform: rotate(-2deg);
              transform: rotate(-2deg);
    }
    90% {
      -webkit-transform: rotate(2deg);
              transform: rotate(2deg);
    }
  }
  @keyframes shake-left {
    0%,
    100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      -webkit-transform-origin: 0 50%;
              transform-origin: 0 50%;
    }
    10% {
      -webkit-transform: rotate(2deg);
              transform: rotate(2deg);
    }
    20%,
    40%,
    60% {
      -webkit-transform: rotate(-4deg);
              transform: rotate(-4deg);
    }
    30%,
    50%,
    70% {
      -webkit-transform: rotate(4deg);
              transform: rotate(4deg);
    }
    80% {
      -webkit-transform: rotate(-2deg);
              transform: rotate(-2deg);
    }
    90% {
      -webkit-transform: rotate(2deg);
              transform: rotate(2deg);
    }
  }
  
  /* ----------------------------------------------
 * Generated by Animista on 2021-12-28 10:16:30
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-right
 * ----------------------------------------
 */
@-webkit-keyframes shake-right {
    0%,
    100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
    }
    10% {
      -webkit-transform: rotate(2deg);
              transform: rotate(2deg);
    }
    20%,
    40%,
    60% {
      -webkit-transform: rotate(-4deg);
              transform: rotate(-4deg);
    }
    30%,
    50%,
    70% {
      -webkit-transform: rotate(4deg);
              transform: rotate(4deg);
    }
    80% {
      -webkit-transform: rotate(-2deg);
              transform: rotate(-2deg);
    }
    90% {
      -webkit-transform: rotate(2deg);
              transform: rotate(2deg);
    }
  }
  @keyframes shake-right {
    0%,
    100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
    }
    10% {
      -webkit-transform: rotate(2deg);
              transform: rotate(2deg);
    }
    20%,
    40%,
    60% {
      -webkit-transform: rotate(-4deg);
              transform: rotate(-4deg);
    }
    30%,
    50%,
    70% {
      -webkit-transform: rotate(4deg);
              transform: rotate(4deg);
    }
    80% {
      -webkit-transform: rotate(-2deg);
              transform: rotate(-2deg);
    }
    90% {
      -webkit-transform: rotate(2deg);
              transform: rotate(2deg);
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2021-12-28 10:21:50
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-bck
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-bck {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes pulsate-bck {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  
  
